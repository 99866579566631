import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { BannerWrapper } from "../components/BannerWrapper";
import { Layout } from "../components/themed";

const InflablesVoladores = () => {
  return (
    <Layout>
      <BannerWrapper>
        <StaticImage
          src="../images/banners/banner_inflables.png"
          alt="Publicidad Aerea - Inflables Voladores"
        />
      </BannerWrapper>
    </Layout>
  );
};

export default InflablesVoladores;
